import {
  Box,
  Divider,
  Flex,
  IconButton,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import CardDestaque from "../../../pages/pagina-inicial/components/cardDestaque";
import { contentSeventhSession } from "../../layouts/text";
import { useLanguage } from "../../contexts/language";
import { useState } from "react";
import { Button } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

interface ISeventhSession {
  title?: string;
  describe?: string;
  srcImg?: string;
  buttonLabel?: string;
  objeto?: any;
  bgColor?: string;
  onClick?: string;
}

export default function SeventhSession({ objeto }: ISeventhSession) {
  const mobile = useBreakpointValue({ base: true, md: false });
  const { language } = useLanguage();
  const text = contentSeventhSession[language];

  const CARDS_PER_PAGE = 4;
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(0, prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1));
  };

  const currentCards = text.cards.slice(
    currentIndex,
    currentIndex + CARDS_PER_PAGE
  );

  return (
    <>
      <Box w={"100vw"} h={{ base: "100%", md: "80vh" }} bgColor={"#FAF9F6"}>
        <Flex
          flexDir={"column"}
          width={{ base: "90vw", md: "90vw" }}
          mx={"auto"}
          h={{ base: "100%", md: "75vh" }}
          my={"auto"}
        >
          {mobile ? (
            <>
              <Text
                textAlign={{ base: "left", md: "center" }}
                fontSize={{ base: "1.25rem", md: "2.5rem" }}
                fontWeight={"bold"}
              >
                {text.title}
              </Text>
              <Divider
                orientation="horizontal"
                borderColor={"#012945"}
                borderWidth="2px"
                width={{ base: "90vw", md: "38vw" }}
              />
            </>
          ) : (
            <Box textAlign={{ base: "left", md: "center" }} width="fit-content">
              <Text
                fontSize={{ base: "1.25rem", md: "2.5rem" }}
                fontWeight="bold"
              >
                {text.title}
              </Text>
              <Divider
                mt={1}
                borderColor={"#012945"}
                borderWidth="2px"
                width="100%"
              />
            </Box>
          )}

          <Text
            fontSize={{ base: "1.5rem", md: "1.5rem" }}
            lineHeight={"1"}
            py={4}
          >
            {text.description}
          </Text>
          <Box />

          <Box h={8}></Box>

          <Flex
            width={"100%"}
            h={"100%"}
            flexDirection={{ base: "column", md: "row" }}
            gap={15}
            alignItems={"center"}
            mx={"auto"}
            my={"auto"}
            justifyContent={"space-evenly"}
          >
            {mobile ? (
              text.cards.map((result: ISeventhSession) => (
                <CardDestaque
                  title={String(result.title)}
                  describe={String(result.describe)}
                  srcImg={String(result.srcImg)}
                  buttonLabel={String(result.buttonLabel)}
                  bgColor={String(result.bgColor)}
                  onClick={String(result.onClick)}
                />
              ))
            ) : (
              <>
                <IconButton
                  onClick={handlePrev}
                  aria-label="Anterior"
                  icon={<ChevronLeftIcon />}
                  isDisabled={currentIndex === 0}
                  colorScheme="yellow"
                  variant="solid"
                  rounded="full"
                  size="lg"
                />
                {currentCards.map((result: ISeventhSession, index: number) => (
                  <CardDestaque
                    key={index}
                    title={String(result.title)}
                    describe={String(result.describe)}
                    srcImg={String(result.srcImg)}
                    buttonLabel={String(result.buttonLabel)}
                    bgColor={String(result.bgColor)}
                    onClick={String(result.onClick)}
                  />
                ))}
                <IconButton
                  onClick={handleNext}
                  aria-label="Próximo"
                  icon={<ChevronRightIcon />}
                  isDisabled={
                    currentIndex + CARDS_PER_PAGE >= text.cards.length
                  }
                  colorScheme="yellow"
                  variant="solid"
                  rounded="full"
                  size="lg"
                />{" "}
              </>
            )}
          </Flex>
        </Flex>
      </Box>
    </>
  );
}
