import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Link,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { useLanguage } from "../../contexts/language";
import { contentFifthSession, contentThirdSession } from "../../layouts/text";

export default function ThirdSession() {
  const { language } = useLanguage();
  const text = contentThirdSession[language];

  return (
    <>
      <Flex
        height={{ base: "75vh", md: "80vh" }}
        bgColor={"#FAF9F6"}
        width={"100vw"}
        flexDir={{ base: "column", md: "row" }}
      >
        <Flex
          width={{ base: "90vw", md: "50vw" }}
          height={{ base: "35vh", md: "75vh" }}
          my={"auto"}
          mx={"auto"}
        >
          <Box
            width={{ base: "100%", md: "48vw" }}
            justifyContent={"center"}
            alignItems={"center"}
            display={"flex"}
            my={"auto"}
          >
            <Image
              src="/section3.png"
              w={{ base: "100%", md: "70vh" }}
              h={{ base: "auto", md: "55vh" }}
              objectFit="cover"
            />
          </Box>
        </Flex>

        <Flex
          width={{ base: "90vw", md: "50vw" }}
          height={{ base: "45vh", md: "80vh" }}
          mx={"auto"}
          my={"auto"}
        >
          <Flex
            height={{ base: "35vh", md: "80vh" }}
            my={"auto"}
            mx={"auto"}
            width={{ base: "100%", md: "80%" }}
            flexDir={"column"}
            gap={{ base: 2, md: 5 }}
            justifyContent={"center"}
          >
            <Box>
              <Text
                fontSize={{ base: "1.25rem", md: "2.5rem" }}
                fontWeight={"bold"}
              >
                {text.title}
              </Text>
              <Divider
                orientation="horizontal"
                borderColor={"#012945"}
                borderWidth="2px"
                width={{ base: "8.300rem", md: "270px" }}
              />
            </Box>

            <Box
              fontSize={{ base: "1rem", md: "1.5rem" }}
              dangerouslySetInnerHTML={{
                __html: text.describe,
              }}
            />
            <Box
              fontSize={{ base: "0.875rem", md: "1.375rem" }}
              dangerouslySetInnerHTML={{
                __html: text.describe2,
              }}
            ></Box>
            <Box marginTop={{ base: "15px", md: "0" }}>
              <Link
                href="https://wa.me/555197818534"
                isExternal
                _hover={{ textDecoration: "none" }}
              >
                <Button
                  colorScheme="orange"
                  borderRadius={{ base: "28px", md: "20" }}
                  w={{ base: 56, md: 64 }}
                  fontSize={{ base: "1rem", md: "1rem" }}
                >
                  {text.buttonLabel}
                </Button>
              </Link>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
