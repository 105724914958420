import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "./routes";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { LanguageProvider } from "./shared/contexts/language";

export const App = () => {
  const breakpoints = {
    sm: "30em", // 480px
    md: "48em", // 768px
    lg: "62em", // 992px
    xl: "80em", // 1280px
    xxl: "96em", // 1536px
    xxxl: "110em",
  };

  const theme = extendTheme({ breakpoints });

  return (
    <LanguageProvider>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ChakraProvider>
    </LanguageProvider>
  );
};
