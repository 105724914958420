import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Link,
  Spacer,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { contentFourthSession } from "../../layouts/text";
import { useLanguage } from "../../contexts/language";
import CardIcon from "../../../pages/pagina-inicial/components/cardIcon";
import CardIconMobile from "../../../pages/pagina-inicial/components/cardIconMobile";

export default function FourthSession() {
  const { language } = useLanguage();
  const text = contentFourthSession[language];
  const mobile = useBreakpointValue({ base: true, md: false });

  return (
    <>
      <Box w={"100vw"} h={"100%"} bgColor={"#FAF9F6"}>
        <Flex
          direction="column"
          alignItems={{ base: "left", md: "center" }}
          px={{ base: 0, md: 28 }}
          width={{ base: "90vw", md: "80vw" }}
          my={"auto"}
          mx={"auto"}
          gap={{ base: 2, md: 2 }}
        >
          {mobile ? (
            <>
              <Text
                textAlign={{ base: "left", md: "center" }}
                fontSize={{ base: "1.25rem", md: "2.5rem" }}
                fontWeight={"bold"}
              >
                {text.title}
              </Text>
            </>
          ) : (
            <Box textAlign={{ base: "left", md: "center" }} width="fit-content">
              <Text
                fontSize={{ base: "1.25rem", md: "2.5rem" }}
                fontWeight="bold"
              >
                {text.title}
              </Text>
            </Box>
          )}

          <Box
            fontSize={{ base: "1rem", md: "1.5rem" }}
            dangerouslySetInnerHTML={{
              __html: text.description,
            }}
            mt={{ base: 2, md: 4 }}
            alignItems={{ base: "left", md: "center" }}
            textAlign={{ base: "left", md: "center" }}
            w={{ base: "100%", md: "45vw" }}
          ></Box>
        </Flex>

        <Box h={{base: 8, md: 16}}></Box>

        <Flex
          flexDir={"column"}
          gap={{ base: 4, md: 10 }}
          w={"90vw"}
          mx={"auto"}
        >
          {text.cards.map((card) => (
            <Flex
              flexDirection={{ base: "column", md: "row" }}
              alignItems={{ base: "center", md: "left" }}
              h={{ base: "35vh", md: "12vh" }}
              gap={65}
              w={"100%"}
              mx={"auto"}
              bgColor={"#012945"}
              borderLeftRadius={{ base: 0, md: "100px" }}
              borderRightRadius={{ base: 0, md: "25px" }}
              borderRadius={{ base: "6px" }}
            >
              {mobile ? (
                <CardIconMobile
                  title={card.titleMobile}
                  describe={card.describeMobile}
                  src={card.srcMobile}
                />
              ) : (
                <CardIcon text={card.text} srcImg={card.src} />
              )}
            </Flex>
          ))}
        </Flex>
        <Box height={{base: 2, md: 20}}></Box>
      </Box>
    </>
  );
}
