import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Image,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef, useState } from "react";

export interface ICardExample {
  text?: string;
  name?: string;
  vocation?: string;
  objeto?: any;
  srcImg?: string;
  bgColor?: string;
  button?: string;
  buttonClose?: string;
}

export default function CardExemplo({
  srcImg,
  text,
  name,
  vocation,
  objeto,
  bgColor,
  button,
  buttonClose,
}: ICardExample) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const textRef = useRef<HTMLDivElement>(null);

  const handleSeeMoreClick = () => {
    onOpen();
    if (textRef.current) {
      textRef.current.scrollTop = 0;
    }
  };

  return (
    <>
      <Card w={{ base: "90%", md: 360 }} h={500} textAlign={"center"}>
        <CardBody
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Image src={srcImg} w={"100px"} h={"100px"} />
          <Flex
            marginTop={"1rem"}
            h={180}
            overflow="hidden"
            sx={{
              "::-webkit-scrollbar": {
                width: "8px", 
              },
              "::-webkit-scrollbar-track": {
                background: "transparent", 
              },
              "::-webkit-scrollbar-thumb": {
                backgroundColor: "yellow.500", 
                borderRadius: "4px",
              },
            }}
            ref={textRef}
          >
            <Text
              marginTop={10}
              px={10}
              textAlign={"center"}
              fontFamily={"Inter, sans-serif"}
              fontSize={{ base: "1rem", md: "1.375rem" }}
            >
              {text}
            </Text>
          </Flex>

          <Button
            onClick={handleSeeMoreClick}
            size="sm"
            colorScheme="blue"
            variant="link"
            mt={2}
            fontWeight="bold"
            _hover={{ textDecoration: "underline" }}
          >
            {button}
          </Button>

          <Text
            marginTop={10}
            px={10}
            fontSize={{ base: "1rem", md: "1rem" }}
            fontFamily={"Inter, sans-serif"}
          >
            {name}
          </Text>
          <Text
            marginTop={2}
            px={10}
            fontWeight={"light"}
            fontSize={{ base: "1rem", md: "1rem" }}
            fontFamily={"Inter, sans-serif"}
          >
            {vocation}
          </Text>
        </CardBody>
      </Card>

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>O que nossos usuários dizem</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex
              alignItems={"center"}
              justifyContent={"center"}
              flexDir={"column"}
              gap={"1rem"}
            >
              <Image src={srcImg} w={"100px"} h={"100px"} />
              <Text
                fontFamily={"Inter, sans-serif"}
                fontSize="1.125rem"
                w={"90%"}
                marginTop={"1rem"}
              >
                {text}
              </Text>
              <Text
                marginTop={10}
                px={10}
                fontSize={{ base: "1rem", md: "1rem" }}
                fontFamily={"Inter, sans-serif"}
              >
                {name}
              </Text>
              <Text
                marginTop={2}
                px={10}
                fontWeight={"light"}
                fontSize={{ base: "1rem", md: "1rem" }}
                fontFamily={"Inter, sans-serif"}
              >
                {vocation}
              </Text>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              {buttonClose}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
