import { useEffect, useState } from "react";
import { Box, Button, Flex, Image, Link, Text } from "@chakra-ui/react";

interface ICardDestaque {
  title: string;
  describe: string;
  buttonLabel: string;
  srcImg: string;
  bgColor?: string;
  onClick?: string;
}

export default function CardDestaque({
  title,
  describe,
  buttonLabel,
  srcImg,
  bgColor,
  onClick,
}: ICardDestaque) {
  const [isZoomed, setIsZoomed] = useState(false);

  useEffect(() => {
    const checkZoom = () => {
      const zoomLevel = window.devicePixelRatio * 100;
      setIsZoomed(zoomLevel > 110); 
    };

    checkZoom(); 
    window.addEventListener("resize", checkZoom); 

    return () => {
      window.removeEventListener("resize", checkZoom);
    };
  }, []);

  const bgBlue = bgColor === "#035A97";

  return (
    <>
      <Flex
        w={{ base: "100%", md: 320 }}
        h={{ base: "60vh", md: "100%" }}
        maxW={{ base: "100%", md: 320 }}
        minW={220}
        maxH={{ base: "3000vh", md: 500 }}
        borderWidth="1px"
        borderRadius="18px"
        overflow="hidden"
        display={"flex"}
        flexDirection={"column"}
        textAlign={"center"}
        alignItems={"center"}
        bgColor={bgColor}
      >
        <Image w={"100%"} h={{ base: "25vh", md: "20vh" }} src={srcImg} />
        <Flex
          w={"100%"}
          h={"30vh"}
          flexDir={"column"}
          my={"auto"}
          justifyContent={"space-evenly"}
          gap={{ base: 2, md: 3 }}
          alignItems={"center"}
        >
          <Box
            h={"8vh"}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Text
              color={bgBlue ? "white" : "normal"}
              fontSize={isZoomed ? "0.9rem" : { base: "1.1rem", md: "1xl" }}
              px={1}
              fontWeight={"bold"}
              textAlign="center" 
            >
              {title}
            </Text>
          </Box>
          <Text
            color={bgBlue ? "white" : "black"}
            w={"95%"}
            px={2}
            h={{ md: "13vh" }}
            fontSize={isZoomed ? "0.9rem" : { base: "1rem", md: "1xl" }}
            fontWeight={"light"}
            overflow={"hidden"}
          >
            {describe}
          </Text>
          <Box w={"80%"} h={"20%"} my={"auto"}>
            <Link href={onClick} isExternal _hover={{ textDecoration: "none" }}>
              <Button
                colorScheme="blue"
                w={"100%"}
                bgColor={bgBlue ? "white" : "#035A97"}
                color={bgBlue ? "#035A97" : "white"}
                minW={{ md: "190px" }}
              >
                {buttonLabel}
              </Button>
            </Link>
          </Box>
        </Flex>
      </Flex>
    </>
  );
}
