import {
  Box,
  Button,
  Card,
  CardBody,
  Divider,
  Flex,
  Image,
  Spacer,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import CardExemplo, {
  ICardExample,
} from "../../../pages/pagina-inicial/components/cardExemplo";
import { useLanguage } from "../../contexts/language";
import { contentSixthSession } from "../../layouts/text";

interface SixthSession {
  objeto?: any;
}

export default function SixthSession({ objeto }: SixthSession) {
  const mobile = useBreakpointValue({ base: true, md: false });

  const { language } = useLanguage();
  const text = contentSixthSession[language];

  return (
    <>
      <Box w={"100vw"} h={"100%"} bgColor={"#FAF9F6"}>
        <Flex
          direction="column"
          alignItems={{ base: "left", md: "center" }}
          px={{ base: 0, md: 28 }}
          width={{ base: "90vw", md: "80vw" }}
          my={"auto"}
          mx={"auto"}
          gap={{ base: 2, md: 2 }}
        >
          {mobile ? (
            <>
              <Text
                textAlign={{ base: "left", md: "center" }}
                fontSize={{ base: "1.25rem", md: "2.5rem" }}
                fontWeight={"bold"}
              >
                {text.title}
              </Text>
              <Divider
                orientation="horizontal"
                borderColor={{base: "#035A97", md:"#012945"}}
                borderWidth="2px"
                width={{ base: "90vw", md: "38vw" }}
              />
            </>
          ) : (
            <Box textAlign={{ base: "left", md: "center" }} width="fit-content">
              <Text
                fontSize={{ base: "1.25rem", md: "2.5rem" }}
                fontWeight="bold"
              >
                {text.title}
              </Text>
              <Divider
                mt={1}
                borderColor={"#012945"}
                borderWidth="2px"
                width="100%"
              />
            </Box>
          )}

          <Text
            fontSize={{ base: "1rem", md: "1.5rem" }}
            fontWeight={"semi-bold"}
            textAlign={{ base: "left", md: "center" }}
          >
            {text.description}
          </Text>
        </Flex>

        <Box h={16}></Box>

        <Flex
          flexDirection={{ base: "column", md: "row" }}
          justifyContent={"space-between"}
          /* justifyContent={"space-evenly"} */
          alignItems={"center"}
          gap={65}
          w={"90vw"}
          mx={"auto"}
        >
          {text.cards.map((result: ICardExample) => (
            <CardExemplo
              text={result.text}
              srcImg={result.srcImg}
              name={result.name}
              vocation={result.vocation}
              button={result.button}
              buttonClose={result.buttonClose}
            />
          ))}
        </Flex>
        <Box height={20}></Box>
      </Box>
    </>
  );
}
