import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { useRef, useState } from "react";

export interface ICardIcon {
  title: string;
  describe: string;
  src: string;
}

export default function CardIconMobile({ title, describe, src }: ICardIcon) {
  return (
    <>
      <Flex
        w={"90%"}
        alignItems={"center"}
        flexDir={"column"}
        gap={15}
        my={"auto"}
      >
        
        <Image src={src} mr={"auto"}/>

        <Text fontSize={{ base: "1.5rem", md: "2.375rem" }} color={"white"} mr={"auto"}>
          {title}
        </Text>
        <Text fontSize={{ base: "1rem", md: "1.375rem" }} color={"white"} mr={"auto"}>
          {describe}
        </Text>
      </Flex>
    </>
  );
}
