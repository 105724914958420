import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { useRef, useState } from "react";

export interface ICardIcon {
  text: string;
  srcImg: string;
}

export default function CardIcon({ srcImg, text }: ICardIcon) {
  return (
    <>
      <Flex
        w={"120px"}
        h={"120px"}
        position={{ base: "relative", md: "absolute" }}
        borderRadius={"full"}
        bgColor={"#F29200"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Image src={srcImg} w={"55%"} h={"55%"} />
      </Flex>
      <Flex>
        <Box
          fontSize={{ base: "1rem", md: "1.375rem" }}
          ml={{ base: "auto", md: "10vw" }}
          color={"white"}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        ></Box>
      </Flex>
    </>
  );
}
