import { Box, Divider, Flex, Image, Link, Text } from "@chakra-ui/react";
import { contentEightSession } from "../../layouts/text";
import { useLanguage } from "../../contexts/language";

export default function EightSession() {
  const { language } = useLanguage();
  const text = contentEightSession[language];

  return (
    <>
      <Box w={"100vw"} bgColor={"#FAF9F6"}>
        <Box h={"5vh"}></Box>
        <Divider
          borderColor={"#012945"}
          borderWidth="2px"
          w={"90vw"}
          mx={"auto"}
        />

        <Box h={"5vh"}></Box>

        <Flex
          w={"90vw"}
          h={"20vh"}
          alignItems={"center"}
          mx={"auto"}
          justifyContent={"space-between"}
        >
          <Flex w={"20vw"}>
            <Image
              src="/devbycreatus.png"
              cursor={"pointer"}
              onClick={() => {
                const a = document.createElement("a");
                a.href = "https://creatusdev.com/";
                a.target = "_blank";
                a.click();
              }}
            />
          </Flex>

          <Flex w={"65vw"} justifyContent={"space-between"}>
            <Text w={"40%"}>{text.description}</Text>
            <Link
              href="https://wa.me/555197818534"
              isExternal
              _hover={{ textDecoration: "none" }}
            >
              <Box>
                <Text fontWeight={"semibold"}>{text.contactTitle.phone}</Text>
                <Text>{text.contactInfo.phone}</Text>
              </Box>
            </Link>
            <Link
              href={text.contactInfo.emailTo}
              isExternal
              _hover={{ textDecoration: "none" }}
            >
              <Box>
                <Text fontWeight={"semibold"}>{text.contactTitle.email}</Text>
                <Text>{text.contactInfo.email}</Text>
              </Box>
            </Link>
          </Flex>
        </Flex>
        <Box h={"5vh"}></Box>
      </Box>
    </>
  );
}
