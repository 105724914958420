import { Box, Divider, Flex, Image, Link, Text } from "@chakra-ui/react";
import { contentNinthSession } from "../../layouts/text";
import { useLanguage } from "../../contexts/language";

export default function NinthSession() {
  const { language } = useLanguage();
  const text = contentNinthSession[language];

  return (
    <>
      <Flex
        w={"90%"}
        justifyContent={"space-evenly"}
        alignItems={"center"}
        marginTop={"15px"}
        flexDir={{ base: "column", md: "row" }}
        fontWeight={"bold"}
        fontSize={"1rem"}
        gap={3}
      >
        <Divider
          orientation="horizontal"
          borderColor={"#012945"}
          borderWidth="2px"
          width={{ base: "100%", md: "360px" }}
          mt={"20px"}
        />
        <Image src={"pultrusionLogoText.png"} mr={"auto"} mt={"20px"} />

        <Text mr={"auto"}>{text.contato}</Text>
        <Link
          href="https://wa.me/555197818534"
          isExternal
          _hover={{ textDecoration: "none" }}
          mr={"auto"}
        >
          <Text mr={"auto"} fontWeight={"semibold"}>
            {text.contatoNumber}
          </Text>
        </Link>
        <Text mr={"auto"}>{text.email}</Text>
        <Text mr={"auto"} fontWeight={"semibold"}>
          {text.contatoEmail}
        </Text>
        <Image src={"devByCreatusFooter.png"} mr={"auto"} mt={"10px"} />
      </Flex>
      <Box h={20}></Box>
    </>
  );
}
