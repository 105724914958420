import { Flex, Grid, Image, Text } from "@chakra-ui/react";
import { contentSecondSession } from "../../layouts/text";
import { useLanguage } from "../../contexts/language";

export default function SecondSession() {
  const { language } = useLanguage();
  const text = contentSecondSession[language];


  return (
    <>
      <Flex w={"100vw"} mx={"auto"} h={{ base: "45vh", md: "55vh" }} bgColor={"#FAF9F6"} >
        <Grid width={"80vw"} mx={"auto"} my={"auto"} h={"35vh"} alignItems={"center"}>
          <Image
            src="/aspasEsquerda.png"
            w={{ base: 10, md: 12 }}
            h={{ base: 10, md: 12 }}
          />
          <Text
            width={"80%"}
            fontSize={{ base: "1.375rem", md: "2rem" }}
            color={"#012945"}
            fontWeight={"bold"}
            mx={"auto"}
            textAlign={"center"}
          >
            {text.title}
          </Text>
          <Image
            marginLeft={"90%"}
            src="/aspasDireita.png"
            w={{ base: 10, md: 12 }}
            h={{ base: 10, md: 12 }}
          />
        </Grid>
      </Flex>
    </>
  );
}

