import { Flex, useBreakpointValue } from "@chakra-ui/react";
import FirstSession from "../../shared/components/firstSession";
import SecondSession from "../../shared/components/secondSession";
import ThirdSession from "../../shared/components/thirdSession";
import FourthSession from "../../shared/components/fourthSession";
import FifthSession from "../../shared/components/fifthSession";
import SixthSession from "../../shared/components/sixthSession";
import SeventhSession from "../../shared/components/seventhSession";
import EightSession from "../../shared/components/eighthSession";
import NinthSession from "../../shared/components/ninthSession";
import { useEffect } from "react";
import { useLanguage } from "../../shared/contexts/language";

export const PaginaInicial: React.FC = () => {
  const { setLanguage } = useLanguage();

  useEffect(() => {
    setLanguage("PT");
  }, []);

  const mobile = useBreakpointValue({ base: true, md: false });

  return (
    <>
      {/* <Flex  alignItems={"center"} bgColor={"#FAF9F6"}> */}
      <Flex
        flexDir={"column"}
        alignItems={"center"}
        w={"100%"}
        height={"fit-content"}
        bgColor={{base: "#FAF9F6", md: "#FAF9F6"}}


      >
        <FirstSession />
        <SecondSession />
        <ThirdSession />
        <FourthSession />
        <FifthSession />
        <SixthSession />
        <SeventhSession />

        {mobile ? <NinthSession /> : <EightSession />}
      </Flex>
      {/* </Flex> */}
    </>
  );
};
