import { ChevronDownIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Image, Link, Spacer, Text } from "@chakra-ui/react";
import { contentFirstSession } from "../../layouts/text";
import { useLanguage } from "../../contexts/language";
import { useState } from "react";

export default function FirstSession() {
  const { language, setLanguage } = useLanguage();
  const [changeLanguage, setChangeLanguage] = useState(false);

  const text = contentFirstSession[language];

  const handleChangeLanguage = (lang: any) => {
    setChangeLanguage(false);
    setLanguage(lang);
  };

  const handleOpenLanguageSelector = () => {
    setChangeLanguage(!changeLanguage);
  };

  const languages = [
    {
      code: "PT",
      label: "Portuguese",
      flag: "https://flagcdn.com/w40/br.png",
    },
    {
      code: "EN",
      label: "English",
      flag: "https://flagcdn.com/w40/us.png",
    },
  ];

  return (
    <Flex
      bgImage="./background-pultrusion.jpeg"
      bgPos={"center"}
      bgSize={"cover"}
      
      w="100vw"
      h={{ base: "50vh", md: "85vh" }}
      mx="auto"
      flexDir="column"
    >
      {/* HEADER */}
      <Flex
        w="90%"
        mx="auto"
        h={{ base: "0vh", md: "12vh" }}
        mt={{ base: 12, md: 0 }}
        py={{ base: 0, md: 8 }}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        <Flex alignItems="center" position="relative">
          <Image
            src={
              language === "PT"
                ? "https://flagcdn.com/w40/br.png"
                : "euaflagc.png"
            }
            w={{ base: 6, md: 12 }}
            h={{ base: 6, md: 10 }}
          />
          <ChevronDownIcon
            onClick={handleOpenLanguageSelector}
            color="white"
            w={12}
            h={12}
            cursor="pointer"
          />

          {changeLanguage ? (
            <Box
              position="absolute"
              top="50px"
              right={0}
              bgColor="white"
              borderRadius="md"
              boxShadow="md"
              p={2}
              zIndex={1}
            >
              <Flex
                gap={2}
                flexDirection={"column"}
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                onClick={handleOpenLanguageSelector}
              >
                {languages.map(({ code, label, flag }) => (
                  <Image
                    key={code}
                    src={flag}
                    alt={label}
                    w={12}
                    h={10}
                    cursor="pointer"
                    onClick={() => handleChangeLanguage(code)}
                  />
                ))}
              </Flex>
            </Box>
          ) : null}
        </Flex>
      </Flex>

      {/* CONTENT */}
      <Flex justifyContent="space-evenly" flexDir="row" h="60vh">
        <Flex
          w={{ base: "100%", md: "50%" }}
          h={{ base: "40vh", md: "50vh" }}
          alignItems="center"
          justifyContent="center"
          flexDir={"column"}
        >
          <Flex align={"center"}>
            <Image
              src="/logoPul.png"
              w={{ base: 10, md: 16 }}
              h={{ base: 10, md: 16 }}
              mx={2}
            />
            <Text
              color="white"
              fontWeight="bold"
              fontSize={{ base: "2.4rem", md: "5.5rem" }}
            >
              PultrusionPro
            </Text>
          </Flex>
          <Text
            color="white"
            fontWeight="bold"
            fontStyle={"uppercase"}
            fontSize={{ base: "0.700rem", md: "1.7rem" }}
          >
            {text.title}
          </Text>
        </Flex>
      </Flex>
      <Box h="5vh"></Box>
    </Flex>
  );
}
