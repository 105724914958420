export type Language = "PT" | "EN";

export const contentFirstSession: Record<
  Language,
  {
    title: string;
    describe: string;
    buttonLabel: string;
  }
> = {
  PT: {
    title: "ALTA PERFORMANCE PARA SUA PULTRUSION",
    describe:
      "Finalmente as respostas claras e objetivas para otimizar o processo de Pultrusion",
    buttonLabel: "Faça Parte",
  },
  EN: {
    title: "HIGH PERFORMANCE FOR YOUR PULTRUSION",
    describe:
      "Finally, clear and objective answers to optimize the Pultrusion process",
    buttonLabel: "Join Us",
  },
};

export const contentSecondSession: Record<Language, { title: string }> = {
  PT: {
    title:
      "Aqui você obtém a vantagem que te leva a vencer a concorrência no mercado de Pultrusion, alta performance, baixo custo e qualidade.",
  },
  EN: {
    title:
      "Here, you gain the advantage to outperform the competition in the Pultrusion market with high performance, low cost, and quality.",
  },
};

export const contentThirdSession: Record<
  Language,
  {
    title: string;
    describe: string;
    describe2: string;
    buttonLabel: string;
  }
> = {
  PT: {
    title: "Quem somos?",
    describe:
      "Somos uma empresa focada no <b>crescimento</b> com <b>competitividade</b> e <b>máxima lucratividade</b> para empresas de pultrusion.",

    describe2:
      "O know how foi adquirido durante <b>30 anos desenvolvendo processos e perfis em laboratório próprio</b>, com fabricação de mais de <b>10 milhões de quilos</b> de perfis pultrudados com total sucesso.",
    buttonLabel: "Consultoria",
  },
  EN: {
    title: "Who are we?",
    describe:
      "We are a company focused on <b>growth</b> with <b>competitiveness</b> and <b>maximum profitability</b> for pultrusion companies.",

    describe2:
      "The know-how was acquired over <b>30 years of developing processes and profiles in our own laboratory</b>, with the production of more than <b>10 million kilograms</b> of pultruded profiles with total success.",
    buttonLabel: "Consultancy",
  },
};

// Conteúdo da Quarta Sessão
export const contentFourthSession: Record<
  Language,
  {
    title: string;
    description: string;
    cards: Array<{
      src: string;
      text: string;
      titleMobile: string;
      describeMobile: string;
      srcMobile: string;
    }>;
  }
> = {
  PT: {
    title: "Desenvolvemos empresas por meio de 3 pilares",
    description:
      "Como resultado dos estudos e pesquisas, desenvolvemos <b>mais de 100 diferentes perfis</b> para <b>mais de 30 nichos no mercado de Pultrusion.</b>",
    cards: [
      {
        src: "/icon1ForthSession.png",
        text: "<b><u>Tecnologia</b></u> Aplicada para obtenção do domínio absoluto do processo de fabricação e sua implantação na cultura da empresa",
        titleMobile: "Tecnologia aplicada",
        describeMobile:
          "para obtenção do domínio absoluto do processo de fabricação e sua implantação na cultura da empresa.",
        srcMobile: "/fourthSessionMobile1.png",
      },
      {
        src: "/icon2FourthSession.png",
        text: "Gestão de data driven administrando <b><u>redução dos custos</b></u> para <b><u>gerar extrema competitividade</b></u> da empresa nos mercados que atua.",
        titleMobile: "Gestão de data driven",
        describeMobile:
          "administrando redução dos custos para gerar extrema competitividade da empresa nos mercados que atua.",
        srcMobile: "/fourthSessionMobile2.png",
      },
      {
        src: "/icon3FourthSession.png",
        text: "<b><u>Growth Marketing:</b></u> Escalabilidade, faturamento, geração de caixa e aumento da participação no mercado.",
        titleMobile: "Growth Marketing",
        describeMobile:
          "escalabilidade, faturamento, geração de caixa e aumento da participação no mercado.",
        srcMobile: "/fourthSessionMobile2.png",
      },
    ],
  },
  EN: {
    title: "We develop companies through 3 pillars",
    description:
      "As a result of studies and research, we have developed <b>over 100 different profiles</b> for <b>more than 30 niches in the Pultrusion market.</b>",
    cards: [
      {
        src: "/icon1ForthSession.png",
        text: "<b><u>Technology</b></u> applied to achieve absolute mastery of the manufacturing process and its implementation into the company's culture.",
        titleMobile: "Applied technology",
        describeMobile:
          "to achieve absolute mastery of the manufacturing process and its implementation into the company's culture.",
        srcMobile: "/fourthSessionMobile1.png",
      },
      {
        src: "/icon2FourthSession.png",
        text: "Data-driven management administering <b><u>cost reduction</b></u> to <b><u>generate extreme competitiveness</b></u> for the company in the markets where it operates.",
        titleMobile: "Data-driven management",
        describeMobile:
          "administering cost reduction to generate extreme competitiveness for the company in the markets where it operates.",
        srcMobile: "/fourthSessionMobile2.png",
      },
      {
        src: "/icon3FourthSession.png",
        text: "<b><u>Growth Marketing:</b></u> Scalability, revenue, cash generation, and increased market share.",
        titleMobile: "Growth marketing",
        describeMobile:
          "scalability, revenue, cash generation, and increased market share.",
        srcMobile: "/fourthSessionMobile2.png",
      },
    ],
  },
};

export const contentFifthSession: Record<
  Language,
  {
    title: string;
    description: string;
    subTitle: string;
    buttonLabel: string;
  }
> = {
  PT: {
    title: "Quem Está por Trás",
    subTitle: "Rogério Menegaz Rodrigues – Um Pioneiro na Pultrusion",
    description:
      "Rogério Menegaz Rodrigues iniciou sua carreira jovem, em um ambiente familiar de fabricação. Aos 16 anos, entrou no mundo dos compósitos, liderando projetos que elevaram o padrão da Pultrusion no Brasil. Com uma metodologia inovadora, Rogério simplificou o processo de Pultrusion, tornando-o mais acessível e eficiente. Sua dedicação e conhecimento técnico garantiram o sucesso de mais de 100 projetos ao longo de sua carreira.",
    buttonLabel: "Consultoria",
  },
  EN: {
    title: "Who's Behind It",
    subTitle: "Rogério Menegaz Rodrigues – A Pioneer in Pultrusion",
    description:
      "Rogério Menegaz Rodrigues began his career at a young age, growing up in a family manufacturing environment. At 16, he entered the world of composites, leading projects that set new standards for Pultrusion in Brazil. With an innovative methodology, Rogério simplified the Pultrusion process, making it more accessible and efficient. His dedication and technical expertise have ensured the success of over 100 projects throughout his career.",
    buttonLabel: "Consultory",
  },
};

export const contentSixthSession: Record<
  Language,
  {
    title: string;
    description: string;
    cards: Array<{
      name: string;
      text: string;
      vocation: string;
      srcImg: string;
      button: string;
      buttonClose: string;
    }>;
  }
> = {
  PT: {
    title: "O que nossos usuários dizem",
    description:
      "O modelo utilizado pela PultrusionPro tem um histórico de sucesso, atestado por usuários que fabricam perfis no chão de fábrica, engenheiros que projetam estruturas e consumidores finais dos produtos.",
    cards: [
      {
        name: "Antônio Eusébio",
        text: "“Eu e o Rogério Menegaz desenvolvemos dezenas de perfis para suprir necessidades de soluções para estruturas pultrudadas nas mais diversas aplicações. Muitas vezes houve a necessidade de alterarmos formulações e reforços para atingir as exigências de resistência mecânica em estruturas. Obtivemos sucesso durante mais de 25 anos de trabalho em parceria, com  excelentes perfis fabricados.”",
        vocation: "Engenheiro Civil",
        srcImg: "/six2.png",
        button: "Ver Mais",
        buttonClose: "Fechar",
      },
      {
        name: "Fábio Trindade De Angelis",
        text: '“Rogério Menegaz, além de concentrar experiência de uso de diversos perfis e placas, após diversos On Job com o design do Produto alinhado com a engenharia estrutural, conseguiu um domínio total das técnicas de fabricação. A sua "Expertise" na fabricação de perfis em pultrusion, representada hoje pela PultrusionPro, resulta em perfis consistentes, com excelente acabamento, aplicados com maestria, resolvendo com problemas estruturais em muitas empresas importantes no mercado.”',
        vocation: "Engenheiro Civil",
        srcImg: "/six1.png",
        button: "Ver Mais",
        buttonClose: "Fechar",
      },
      {
        name: "Cícero da Silva",
        text: "“Lá se vão quase 30 anos. Boa parte desses anos usando a tecnologia PultrusionPro. Isso fez toda a diferença na minha jornada, pois assim tenho o total controle da operação. Aumento da produtividade,  sem comprometer a qualidade. Com a tecnologia PultrusionPro posso evoluir não só na operação em si, mais também outros campos da pultrusion, como projetos, parte química, formulação e seus componentes. Até mesmo na parte de liderança de equipes e treinamentos. Tem sido uma grande escola na minha vida. Hoje posso dizer que domino o processo, graças a PultrusionPro.”",
        vocation: "Operador de Pultrusion",
        srcImg: "/ciceroImg.png",
        button: "Ver Mais",
        buttonClose: "Fechar",
      },
      {
        name: "Rodrigo Daniel Vasquez Lagos",
        text: "“Quem procura ter uma produção estável e produtos de excelente qualidade deve seguir ao método PultrusionPro. É importantíssimo neste processo, onde há tão pouca informação disponível, carência esta suprida por ele! Escrevo esse relatório para demonstrar o “antes e o depois” do uso da tecnologia PultrusionPro, apresentada por Rogério menegaz. Minha formação foi toda embasada na experiência de operadores mais antigos na fábrica. A empresa em que atuo trouxe o Rogério, não o conhecia pessoalmente, mas já ouvia falar que ele era referência! Com a sua chegada tudo o que haviam falado foi se confirmando, primeiro organizou a equipe. Depois, alterou as formulações, alinhou e modificou placas de introdução e ferramental, ajustou as temperaturas de processamento ganhando uma velocidade absurda na produção. Diz que para ter bons resultados precisamos de fibras alinhadas preenchendo o molde, molhando 100% das fibras. A produção acontece com leveza na tração do perfil. Tudo  isso acompanhado de fichas de parâmetros que dão total controle e segurança na operação, algo que antes eu não tinha acesso. Antes havia um grande número de rompimentos, que ninguém sabia o que tinha ocasionado. Com o método, se obteve uma produção plena e de qualidade impar, uma técnica de fácil interpretação pelo fato de tudo ser intuitivo. Produzimos então perfis como nunca tinha visto, com um brilho e um acabamento fantástico, uma cura impressionante”",
        vocation: "Operador de Pultrusion",
        srcImg: "/rodrigoImg.png",
        button: "Ver Mais",
        buttonClose: "Fechar",
      },
    ],
  },
  EN: {
    title: "What our users say",
    description:
      "The model used by PultrusionPro has a proven track record of success, endorsed by users who manufacture profiles on the factory floor, engineers who design structures, and end consumers of the products.",

    cards: [
      {
        name: "Antônio Eusébio",
        text: "“Rogério Menegaz and I developed dozens of profiles to meet the needs of pultruded structure solutions in various applications. Many times, we had to adjust formulations and reinforcements to meet the mechanical resistance requirements in structures. We achieved success over more than 25 years of working together, with excellent profiles manufactured.”",
        vocation: "Civil Engineer",
        srcImg: "/six2.png",
        button: "See More",
        buttonClose: "Close",
      },
      {
        name: "Fábio Trindade De Angelis",
        text: "“Rogério Menegaz, besides having extensive experience using various profiles and plates, after multiple on-the-job sessions aligning product design with structural engineering, achieved total mastery of manufacturing techniques. His expertise in pultrusion profile manufacturing, now represented by PultrusionPro, results in consistent profiles with excellent finishing, applied masterfully to solve structural issues for many major companies in the market.”",
        vocation: "Civil Engineer",
        srcImg: "/six1.png",
        button: "See More",
        buttonClose: "Close",
      },
      {
        name: "Cícero da Silva",
        text: "“It's been almost 30 years. Many of these years using PultrusionPro technology. It made all the difference in my journey, as it gave me complete control over the operation. Increased productivity without compromising quality. With PultrusionPro technology, I can evolve not only in operations but also in other fields of pultrusion, such as project management, chemistry, formulations, and their components. Even in leadership and training. It's been a great school in my life. Today, I can say I master the process, thanks to PultrusionPro.”",
        vocation: "Pultrusion Operator",
        srcImg: "/ciceroImg.png",
        button: "See More",
        buttonClose: "Close",
      },
      {
        name: "Rodrigo Daniel Vasquez Lagos",
        text: "“Those looking for stable production and excellent quality products should follow the PultrusionPro method. It's crucial in this process, where so little information is available, a gap that PultrusionPro fills perfectly! I write this report to show the 'before and after' of using PultrusionPro technology, introduced by Rogério Menegaz. My training was based on the experience of older operators at the factory. The company I work for brought Rogério in; I didn't know him personally, but I had heard he was a reference! Upon his arrival, everything said about him was confirmed. First, he organized the team, then adjusted formulations, aligned and modified plates and tooling, and fine-tuned processing temperatures, achieving incredible production speed. He emphasizes that to achieve good results, fibers must be aligned to fill the mold and 100% saturated. The production flows effortlessly with profile traction. This is all supported by parameter sheets that provide complete control and safety in operations—something I previously lacked. There used to be a high number of breakages that no one could explain. With this method, we achieved consistent and exceptional-quality production, with profiles featuring a brilliant finish and impressive curing. A technique that's intuitive and easy to understand, allowing us to produce profiles like never before.”",
        vocation: "Pultrusion Operator",
        srcImg: "/rodrigoImg.png",
        button: "See More",
        buttonClose: "Close",
      },
    ],
  },
};

export const contentSeventhSession: Record<
  Language,
  {
    title: string;
    description: string;
    cards: Array<{
      title: string;
      describe: string;
      srcImg: string;
      buttonLabel: string;
      bgColor: string;
      onClick: string;
    }>;
  }
> = {
  PT: {
    title: "Nossos destaques",
    description: "Tenha acesso a conteúdos sobre Pultrusion.",
    cards: [
      {
        title: "Ebook",
        describe:
          "A Pultrusão do Brasil mostrará na Feiplar as potencialidades dos perfis estruturais de plástico reforçados com fibras de vidro fabricados pelo processo de pultrusão.",
        srcImg: "/emBreve.png",
        buttonLabel: "Em Breve",
        bgColor: "#035A97",
        onClick: "http://www.amazon.com",
      },
      {
        title: "Obras em empresas de fertilizantes",
        describe:
          "A Pultrusão do Brasil é referência brasileira na produção de perfis pultrudados de alta qualidade e no desenvolvimento de perfis de grande complexidade técnica..",
        srcImg: "/seven13.png",
        buttonLabel: "Ver Documento",
        bgColor: "white",
        onClick:
          "https://drive.google.com/file/d/1ne4fFFC6ynqQ9l_6aog2anVb-2JMkQ-8/view?usp=sharing",
      },
      {
        title: "Telhas Pultrudadas",
        describe:
          "A telha pultrudada é produzida com resinas termofixas resistente química e mecanicamente. O produto é indicado para ambientes industriais ou ...",
        srcImg: "/seven12.png",
        buttonLabel: "Ver Folder",
        bgColor: "white",
        onClick:
          "https://drive.google.com/file/d/1gBg5h_W-dTpfGTkHHsuC0QnH0Ia0U89w/view?usp=sharing",
      },
      {
        title: "Torres de mineração",
        describe:
          "A Pultrusão do Brasil apresenta neste documento a Torre de Mineração Pultrudada. A obra é única no Brasil. Está instalada na unidade ....",
        srcImg: "/seven14.png",
        buttonLabel: "Ver Documento",
        bgColor: "white",
        onClick:
          "https://drive.google.com/file/d/1Y4hXm0ZnKBxfSY2Xxb-eTx9FIT49sZ69/view?usp=sharing",
      },
      {
        title:
          "Galpão industrial estruturas de telhado fechamento lateral e telhado",
        describe:
          "Pultrusion foi usada em 2013 como material estrutural e de cobertura para um galpão de armazenagem de sal...",
        srcImg: "/seven15.png",
        buttonLabel: "Ver Documento",
        bgColor: "white",
        onClick:
          "https://drive.google.com/file/d/1OFqxkreUSzASEPTBuhgAJCkmjsoRReHe/view?usp=sharing",
      },
    ],
  },
  EN: {
    title: "Our Highlights",
    description: "Access exclusive content about Pultrusion.",
    cards: [
      {
        title: "Ebook",
        describe:
          "Pultrusion do Brasil will showcase at Feiplar the potential of structural plastic profiles reinforced with fiberglass, manufactured through the pultrusion process.",
        srcImg: "/emBreve.png",
        buttonLabel: "Coming Soon",
        bgColor: "#035A97",
        onClick: "http://www.amazon.com",
      },
      {
        title: "Projects in Fertilizer Companies",
        describe:
          "Pultrusion do Brasil is a Brazilian benchmark in the production of high-quality pultruded profiles and in the development of technically complex profiles.",
        srcImg: "/seven12.png",
        buttonLabel: "View Document",
        bgColor: "white",
        onClick:
          "https://drive.google.com/file/d/1ne4fFFC6ynqQ9l_6aog2anVb-2JMkQ-8/view?usp=sharing",
      },
      {
        title: "Pultruded Roof Sheets",
        describe:
          "Pultruded roof sheets are made from thermoset resins, offering chemical and mechanical resistance. Ideal for industrial environments or ...",
        srcImg: "/seven13.png",
        buttonLabel: "View Brochure",
        bgColor: "white",
        onClick:
          "https://drive.google.com/file/d/1gBg5h_W-dTpfGTkHHsuC0QnH0Ia0U89w/view?usp=sharing",
      },
      {
        title: "Mining Towers",
        describe:
          "Pultrusion do Brasil presents in this document the Pultruded Mining Tower. This unique project in Brazil is located in the unit ....",
        srcImg: "/seven14.png",
        buttonLabel: "View Document",
        bgColor: "white",
        onClick:
          "https://drive.google.com/file/d/1Y4hXm0ZnKBxfSY2Xxb-eTx9FIT49sZ69/view?usp=sharing",
      },
      {
        title: "Industrial Warehouse Roof Structures and Side Enclosures",
        describe:
          "Pultrusion was used in 2013 as a structural and roofing material for a salt storage warehouse...",
        srcImg: "/seven15.png",
        buttonLabel: "View Document",
        bgColor: "white",
        onClick:
          "https://drive.google.com/file/d/1OFqxkreUSzASEPTBuhgAJCkmjsoRReHe/view?usp=sharing",
      },
    ],
  },
};

export const contentEightSession: Record<
  Language,
  {
    description: string;
    logoSrc: string;
    contactTitle: {
      whatsapp: string;
      phone: string;
      email: string;
      emailTo?: string;
    };
    contactInfo: {
      whatsapp: string;
      phone: string;
      email: string;
      emailTo?: string;
    };
  }
> = {
  PT: {
    description:
      "Com conhecimento de cada detalhe e décadas de experiência, nossa empresa está comprometida em oferecer soluções completas e inovadoras no processo de Pultrusion, garantindo alta qualidade, eficiência e resultados excepcionais.",
    logoSrc: "/devbycreatus.png",
    contactTitle: {
      whatsapp: "",
      phone: "Whatsapp",
      email: "Email",
    },
    contactInfo: {
      whatsapp: "https://wa.me/555197818534",
      phone: "55 51 99781 8534",
      email: "rogerio@pultrusao.com.br",
      emailTo: "mailto:rogerio@pultrusao.com.br",
    },
  },
  EN: {
    description:
      "With knowledge of every detail and decades of experience, our company is committed to offering complete and innovative solutions in the Pultrusion process, ensuring high quality, efficiency, and exceptional results.",
    logoSrc: "/devbycreatus.png",
    contactTitle: {
      whatsapp: "",
      phone: "Whatsapp",
      email: "Email",
      emailTo: "mailto:rogerio@pultrusao.com.br",
    },
    contactInfo: {
      whatsapp: "https://wa.me/555197818534",
      phone: "55 51 99781 8534",
      email: "rogerio@pultrusao.com.br",
      emailTo: "mailto:rogerio@pultrusao.com.br",
    },
  },
};

export const contentNinthSession: Record<
  Language,
  {
    logoSrc: string;
    email: string;
    contatoEmail: string;
    contatoNumber: string;
    contato: string;
  }
> = {
  PT: {
    logoSrc: "logoPul.png",
    email: "Email",
    contatoEmail: "rogerio@pultrusao.com.br",
    contatoNumber: "55 51 99781 8534",
    contato: "Contato",
  },
  EN: {
    logoSrc: "logoPul.png",
    email: "Email",
    contatoEmail: "rogerio@pultrusao.com.br",
    contatoNumber: "55 51 99781 8534",
    contato: "Contact",
  },
};
